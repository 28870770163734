import React, { createContext, useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { getItem } from "../../services/util";
// Create the Auth Context
const ApiContext = createContext();

// AuthProvider component (Provider)
export const ApiProvider = ({ children }) => {
  const [apiError, setApiError] = useState("");
  const [loader, setLoader] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (userType === null) {
        if (getItem("adminId")) {
          setUserType("admin");
        } else {
          setUserType("user");
        }
      }
    }
  }, []);
  return (
    <ApiContext.Provider
      value={{
        apiError,
        setApiError,
        loader,
        setLoader,
        userType,
        setUserType,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

// Custom Hook to use authentication
export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useAuth must be used within an ApiProvider");
  }
  return context;
};
