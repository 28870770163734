// Import theme configurations from JSON files
import warmTheme from './warmTheme.json';
import lightTheme from './lightTheme.json';
import darkTheme from './darkTheme.json';
import defaultTheme from './defaultTheme.json';
import greyGreen from './greyGreenTheme.json'
import browny from'./brownyTheme.json'
import softPink from './softPink.json'
import blueLime from './blueLimeTheme.json'

// Export the imported themes
export { warmTheme, lightTheme, darkTheme,defaultTheme,greyGreen,browny,softPink,blueLime };
