// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modalWrap button:not(#closeBtn) {
    background-color: #b5d349;
    min-height: 30px;

    border: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #435500;
    border-radius: 4px;
    padding: .2rem;
    padding-inline:.8rem;
}

#modalWrap{
    min-width: 400px;
}
#closeBtn{
    opacity:0%;
}
#closeBtn:hover{
    opacity:100%;
}

@media (max-width:900px){

    #modalWrap{
        width:90vw;
        min-width:85vw;
        max-width:95vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;;IAEhB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;AACd;AACA;IACI,YAAY;AAChB;;AAEA;;IAEI;QACI,UAAU;QACV,cAAc;QACd,cAAc;IAClB;AACJ","sourcesContent":["#modalWrap button:not(#closeBtn) {\n    background-color: #b5d349;\n    min-height: 30px;\n\n    border: none;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: center;\n    color: #435500;\n    border-radius: 4px;\n    padding: .2rem;\n    padding-inline:.8rem;\n}\n\n#modalWrap{\n    min-width: 400px;\n}\n#closeBtn{\n    opacity:0%;\n}\n#closeBtn:hover{\n    opacity:100%;\n}\n\n@media (max-width:900px){\n\n    #modalWrap{\n        width:90vw;\n        min-width:85vw;\n        max-width:95vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
