const localStorageHandler = (() => {
  // Check if localStorage is supported
  const isLocalStorageSupported = () => {
    try {
      const testKey = "__test__";
      window.localStorage.setItem(testKey, "test");
      window.localStorage.removeItem(testKey);
      return true;
    } catch (error) {
      console.error("LocalStorage is not supported:", error);
      return false;
    }
  };

  // Set an item in localStorage
  const setItem = (key, value) => {
    if (!isLocalStorageSupported()) return;
    try {
      const valueToStore =
        typeof value === "string" ? value : JSON.stringify(value);
      window.localStorage.setItem(key, valueToStore);
      console.log(`Item set successfully: ${key}`);
    } catch (error) {
      console.error(`Failed to set item "${key}" in localStorage:`, error);
      return null;
    }
  };

  // Get an item from localStorage
  const getItem = (key) => {
    if (!isLocalStorageSupported()) return null;

    try {
      const storedValue = window.localStorage.getItem(key);

      // Check if the value exists and attempt to parse it
      if (storedValue !== null) {
        try {
          return JSON.parse(storedValue);
        } catch (parseError) {
          
          return storedValue; // Return the raw value if parsing fails
        }
      }

      return null; // Return null if the key does not exist
    } catch (error) {
      console.error(`Failed to get item "${key}" from localStorage:`, error);
      return null; // Return null if any error occurs
    }
  };

  // Get an item from localStorage
  const getAuth = (key) => {
    if (!isLocalStorageSupported()) return null;
    try {
      const storedValue = window.localStorage.getItem(key);
      return storedValue ? storedValue : null;
    } catch (error) {
      console.error(`Failed to get item "${key}" from localStorage:`, error);
      return null;
    }
  };

  // Delete an item from localStorage
  const deleteItem = (key) => {
    if (!isLocalStorageSupported()) return;
    try {
      window.localStorage.removeItem(key);
      console.log(`Item deleted successfully: ${key}`);
    } catch (error) {
      console.error(`Failed to delete item "${key}" from localStorage:`, error);
    }
  };

  // Clear all items from localStorage
  const clear = () => {
    if (!isLocalStorageSupported()) return;
    try {
      window.localStorage.clear();
      console.log("All items cleared from localStorage.");
    } catch (error) {
      console.error("Failed to clear localStorage:", error);
    }
  };

  const validateMobileNumber = (value) => {
    if (!value?.trim()) {
      return { valid: false, message: "Mobile number is required." };
    }
    if (!/^\+91[6-9]\d{9}$/.test(value)) {
      return {
        valid: false,
        message: "Mobile number must start with 6-9 and be 10 digits long.",
      };
    }
    return { valid: true, message: "" };
  };

  const validateEmail = (value) => {
    if (!value?.trim()) {
      return { valid: false, message: "Email is required." };
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return { valid: false, message: "Please enter a valid email address." };
    }
    return { valid: true, message: "" };
  };

  const validateDob = (value) => {
    if (!value?.trim()) {
      return { valid: false, message: "Date of birth is required." };
    }
    const today = new Date();
    const selectedDate = new Date(value);
    const age = today.getFullYear() - selectedDate.getFullYear();
    const isPast = today >= selectedDate;
    console.log(today, selectedDate, isPast, value, age);
    if (!isPast) {
      return {
        valid: false,
        message: (
          <p className="error">*Date of birth cannot be in the future.</p>
        ),
      };
    }

    return { valid: true, message: null };
  };

  const validateContent = (value) => {
    if (!value?.trim()) {
      return {
        valid: false,
        message: <p className="error">*Content cannot be empty.</p>,
      };
    }
    return { valid: true, message: "" };
  };

  const validatePassword = (value) => {
    if (!value?.trim()) {
      return {
        valid: false,
        message: <p className="error">*Password cannot be empty.</p>,
      };
    }

    if (value.length < 8) {
      return {
        valid: false,
        message: (
          <p className="error">*Password must be at least 8 characters long.</p>
        ),
      };
    }

    if (!/[A-Z]/.test(value)) {
      return {
        valid: false,
        message: (
          <p className="error">
            *Password must include at least one uppercase letter.
          </p>
        ),
      };
    }

    if (!/[a-z]/.test(value)) {
      return {
        valid: false,
        message: (
          <p className="error">
            *Password must include at least one lowercase letter.
          </p>
        ),
      };
    }

    if (!/[0-9]/.test(value)) {
      return {
        valid: false,
        message: (
          <p className="error">*Password must include at least one number.</p>
        ),
      };
    }

    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return {
        valid: false,
        message: (
          <p className="error">
            *Password must include at least one special character.
          </p>
        ),
      };
    }

    return { valid: true, message: "" };
  };

  return {
    setItem,
    getItem,
    deleteItem,
    getAuth,
    clear,
    validateDob,
    validateEmail,
    validateMobileNumber,
    validateContent,
    validatePassword,
  };
})();

// Example usage:
// localStorageHandler.setItem("name", "Alice");
// const name = localStorageHandler.getItem("name");
// localStorageHandler.deleteItem("name");
// localStorageHandler.clear();
export const {
  setItem,
  getItem,
  deleteItem,
  getAuth,
  clear,
  validateDob,
  validateEmail,
  validateMobileNumber,
  validateContent,
  validatePassword,
} = localStorageHandler;
