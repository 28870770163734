export { ReactComponent as IconCardDesignAdmin } from "./svg/cardObj.svg";
export { ReactComponent as IconPublishedResults } from "./svg/published_results.svg";
export { ReactComponent as IconRegistrationSteps } from "./svg/registration_steps.svg";
export { ReactComponent as IconUpcomingExams } from "./svg/upcoming_exams.svg";
export { ReactComponent as IconUserList } from "./svg/user_list.svg";
export { ReactComponent as IconLogo} from "./svg/logo.svg";
export { ReactComponent as IconUpload} from "./svg/upload.svg";
export { ReactComponent as IconClose} from "./svg/closeBtn.svg";
export { ReactComponent as IconCheck} from "./svg/check.svg";
export { ReactComponent as IconWarning} from "./svg/warning.svg";
export { ReactComponent as IconCalendar} from "./svg/IconCalender.svg";
export { ReactComponent as IconEdit} from "./svg/edit.svg";
export { ReactComponent as IconWhatsapp} from "./svg/whatsapp.svg";
export { ReactComponent as IconCountry} from "./svg/country.svg";
export { ReactComponent as IconSchool} from "./svg/school.svg";
export { ReactComponent as IconLevel} from "./svg/level.svg";
export { ReactComponent as IconSearch} from "./svg/search.svg";
export { ReactComponent as IconAdd} from "./svg/add.svg";
export { ReactComponent as IconBack} from "./svg/back.svg";
export { ReactComponent as IconDrop} from "./svg/dropDown.svg";
export { ReactComponent as IconEmail }from"./svg/email.svg";
export { ReactComponent as IconPhone} from "./svg/phone.svg";
export { ReactComponent as IconUser} from "./svg/User.svg";
export { ReactComponent as IconWave} from "./svg/wave.svg";
export { ReactComponent as IconLoginImage } from './svg/LoginPageLeftImage.svg';
export { ReactComponent as IconForgotpswd } from './svg/IconForgotpswd.svg';
export { ReactComponent as IconGoogle } from './svg/Google.svg';
export { ReactComponent as IconCenter } from './svg/IconCenter.svg';
export { ReactComponent as IconIdea } from './svg/idea.svg';
export { ReactComponent as IconSave } from './svg/SavedIcon.svg';
export { ReactComponent as IconEye} from "./svg/eyeicon.svg";
export { ReactComponent as IconEdit1} from "./svg/editicon.svg";
export { ReactComponent as IconDelete1} from "./svg/deleteicon.svg";
export { ReactComponent as IconArrow} from "./svg/Arrow.svg";
export { ReactComponent as Iconbulb} from "./svg/IdeaBulb.svg";
export { ReactComponent as IconBackArrow} from "./svg/backIcon.svg";
export { ReactComponent as IconDownArrow} from "./svg/Down Arrow.svg";
export { ReactComponent as IconSmallUpload} from "./svg/mobUpload.svg";
export { ReactComponent as FilterFrame } from "./svg/filterFrame.svg";
export { ReactComponent as MobAppLogo } from "./svg/MobAppLogo.svg";
export { ReactComponent as IconGotos } from "./svg/goto.svg";