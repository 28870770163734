import React from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import {UserProvider} from "./components/context/Userprovider.jsx";
import "./App.css";
import Main from "./pages/Main/Main.jsx";
import Theme from "./components/context/Theme.js";
import { AuthProvider } from './components/context/AuthContext.js';
import { ApiProvider } from './components/context/ApiContext.jsx';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

function App() {
  return (
    <Router>
      <div className="App">
       
          <Theme>
          <ApiProvider>
             <AuthProvider>
             <UserProvider>
             <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Main/>
              </LocalizationProvider>
              </UserProvider>
              </AuthProvider>
              </ApiProvider>
  
          </Theme>
       
      </div>
    </Router>
  );
}

export default App