import React, { useEffect, useState,Suspense } from "react";
import {  Routes, Route } from "react-router-dom";
import style from "./Main.module.scss";
import { getItem } from "../../services/util";
import { useApi } from "../../components/context/ApiContext";
import SnackBar from "../../components/SnackBar/SnackBar";
import Loader from '../../components/loader/Loader';
const AdminHome = React.lazy(() => import("../AdminHome/AdminHome"));
const PrivateRoute = React.lazy(() => import("../../components/privateRoute/PrivateRoute"));
const RegistrationSteps = React.lazy(() => import("../RegistrationSteps/RegistrationSteps"));
const PublishedList = React.lazy(() => import("../PublishedList/PublishedList"));
const RegistrationList = React.lazy(() => import("../RegistrationList/RegistrationList"));
const RegistrationStudent = React.lazy(() => import("../StudentSide/RegistrationStudent"));
const UserList = React.lazy(() => import("../UserList/UserList"));
const AddExam = React.lazy(() => import("../Exam/AddExam"));
const UserCreation = React.lazy(() => import("../UserCreation/UserCreation"));
const LoginPage = React.lazy(() => import("../../components/Login/LoginPage"));
const NewProfile = React.lazy(() => import("../../components/Login/NewProfile"));
const Password = React.lazy(() => import("../../components/Login/Password"));
const OTPPage = React.lazy(() => import("../../components/Login/GenrateOtp"));
const Resetpassword = React.lazy(() => import("../../components/Login/ResetPassword"));
const TraineeDashboard = React.lazy(() => import("../../components/TraineeDashboard/TraineeDashboard"));
const PublishedResult = React.lazy(() => import("../AddPublishedResult/PublishedResult"));
const Nodata = React.lazy(() => import("../../components/misc/Nodata"))

function Main() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleOpenSnackbar = () => {
    setSnackbarOpen(true);
  };
  const { apiError, setApiError ,userType} = useApi();

  useEffect(() => {
    if (apiError) {
      handleOpenSnackbar();
      setApiError("");
    }

    console.log(getItem("adminId") === "056bYDQNSGHDDAg", getItem("adminId"));
  }, [apiError]);

const admin_id=userType

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <div className={style.main}>
      <SnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={"Something Went Wrong"}
        autoHideDuration={4000}
      />
           <Suspense fallback={<Loader/>}>
      <Routes>
        <Route path="/nodata" element={<Nodata />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/createaccount" element={<NewProfile />} />
        <Route path="/forgetpswd" element={<Password />} />
        <Route path="/generate-otp" element={<OTPPage />} />
        <Route path="/reset-pswd" element={<Resetpassword />} />
       
        {(admin_id=== "user"||!admin_id )&& (
          <Route path="/" element={<TraineeDashboard />} />
        )}
        <Route path="/registration_student" element={<RegistrationStudent />} />

        {/* <Route path="/" element={<TraineeDashboard />} /> */}
        {admin_id === "admin" ? (
          <Route
            element={
              <PrivateRoute
                roleId={admin_id}
                allowedRoles={["056bYDQNSGHDDAg"]}
              />
            }
          >
            <Route path="/" element={<AdminHome />} />
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/exam" element={<AddExam />} />
            <Route path="/registration" element={<RegistrationSteps />} />
            <Route
              path="/publishedList"
              element={
                <PublishedList
                  pageTitle={"Published Exam Result List"}
                  navigateTo={"/add_result"}
                  buttonTitle={"Add Result"}
                  type={"result"}
                />
              }
            />
            <Route path="/user_list" element={<UserList />} />
            <Route path="/add_exam" element={<AddExam />} />
            <Route path="/registration_list" element={<RegistrationList />} />
            <Route path="/add_result" element={<PublishedResult />} />
            <Route path="/profile" element={<UserCreation profile={true} />} />
            <Route path="/user_creation" element={<UserCreation />} />
            <Route
              path="/upcoming_exam"
              element={
                <PublishedList
                  pageTitle={"Upcoming Exam List"}
                  navigateTo={"/add_exam"}
                  buttonTitle={"Add Exam"}
                  type={"exam"}
                />
              }
            />
          </Route>
        ) : (
          <Route
            element={
              <PrivateRoute
                roleId={admin_id}
                allowedRoles={[getItem("user_id")]}
              />
            }
          >
            <Route
              path="/upcoming_exam"
              element={
                <PublishedList
                  pageTitle={"Upcoming Exam List"}
                  navigateTo={"/add_exam"}
                  buttonTitle={"Add Exam"}
                  type={"exam"}
                />
              }
            />
            <Route path="/user_creation" element={<UserCreation />} />
            <Route path="/profile" element={<UserCreation profile={true} />} />
          </Route>
        )}
      </Routes>
      </Suspense>
  
    </div>
  );
}

export default Main;
