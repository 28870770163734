import React, { createContext, useState, useEffect, useContext } from "react";
import { usePrivateRequest } from "../../hooks/useRequests";
import { getItem } from "../../services/util";
import { set } from "date-fns";
import Listcard from '../../pages/PublishedList/ListCard'
import Skeleton from "@mui/material/Skeleton";
const UserContext = createContext();

// Create a custom hook to use the UserContext
export const useUser = () => {
  return useContext(UserContext);
};

// Create a provider wrapper
export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const { privateApiRequest ,loaderScreen} = usePrivateRequest();
  
  useEffect(() => {
    const access_token = getItem("token");
    const admin_id = getItem("adminId");

    

    if (admin_id) {
      setIsAdmin(true);
    }

    if (!admin_id && access_token) {
      fetchUserData();
    }
  }, []);
  const reset =()=>{
    setIsAdmin(false);
    setUserData(null);
  }
  const fetchUserData = async () => {
    try {
      const user = await privateApiRequest({
        url: "studentsapp/showuserdetails/",
        method: "GET",
        data: {},
      });

      if (user.status === 200) {
        setUserData(user.data);
        setIsAdmin(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

const SkeletonCard =()=>{
 return <>

<Skeleton>
    <Listcard/>
    </Skeleton>
    <Skeleton>
    <Listcard/>
    </Skeleton>
    <Skeleton>
    <Listcard/>
    </Skeleton>
    <Skeleton>
    <Listcard/>
    </Skeleton>
    </>
}


  return (
    <UserContext.Provider value={{SkeletonCard,reset,loaderScreen, userData,fetchUserData, token, setToken, setIsAdmin,isAdmin}}>
      {children}
    </UserContext.Provider>
  );
};
