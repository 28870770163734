import React, { createContext, useState, useEffect, useContext } from "react";
import {usePublicRequest,usePrivateRequest} from "../../hooks/useRequests"; // Import the useRequest hook
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { getItem, setItem,clear } from "../../services/util";
import { useApi } from "../../components/context/ApiContext";
// Create the Auth Context
const AuthContext = createContext();

// AuthProvider component (Provider)
export const AuthProvider = ({ children }) => {
   const { setUserType} = useApi();
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn"));
  const [role, setRole] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [apiError, setApiError] = useState("");
const navigate =useNavigate()
  const { data, loading,errorState, publicApiRequest } = usePublicRequest(); // Use the request hook
  const { privateApiRequest } = usePrivateRequest();
  // Check for login status, token, and role in localStorage on page load
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedRole = localStorage.getItem("role");
    const storedLoginStatus = localStorage.getItem("isLoggedIn");

    if (storedToken && storedLoginStatus === "true") {
      console.log("refresh")
      setToken(storedToken);
      setRole(storedRole);
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(()=>{
   if(token){
    const decoded = jwtDecode(token);
    console.log("decode",decoded)
   }
  },[token])

  // Login function
  const login = async (email, password) => {
    try {
        clear()
      const response = await publicApiRequest({
        url: "goetheapp/login/",
        method: "POST",
        data: {"email":email,"password":password},
      });
      console.log("response : ",data)
     if(response.status===200){

        console.log("response : ",response.data)

        const { access_token, role,redirect_url } = response.data;
        localStorage.setItem("token", access_token); // Store token in localStorage
        localStorage.setItem("role", role); // Store role in localStorage
        localStorage.setItem("isLoggedIn", "true"); // Store login status
  
        setToken(token);
        setRole(role);
        setIsLoggedIn(true);
        if( redirect_url==="/adminpage/"){
         setUserType("admin")
       localStorage.setItem("adminId", "056bYDQNSGHDDAg");
       navigate("/")

        }
  else{
    setUserType("user")
    console.log("response : ","fixx")
    localStorage.removeItem("adminId");
    navigate("/")
        const userData=await privateApiRequest({
            url: "studentsapp/showuserdetails/",
            method: "GET",
            data: {},
          })
          if(userData.status===200){
            console.log(userData.data)
            localStorage.setItem("user", userData.data.first_name);
            localStorage.setItem("profile_pic", `${userData.data.profile_picture}`);
            localStorage.setItem("user_id", userData.data.id);
              setItem("details",userData.data)
            navigate("/")
          }
      
          console.log("pp",userData)
  }
     }
    
  
    

    } catch (err) {
      console.error("Login Error: ", err);
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem("token"); // Remove token from localStorage
    localStorage.removeItem("role"); // Remove role from localStorage
    localStorage.removeItem("isLoggedIn"); // Remove login status from localStorage
    localStorage.removeItem("adminId");
    clear()
    localStorage.clear()
    setToken(null);
    setRole(null);
    setIsLoggedIn(false);
  };


  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        role,
        token,
        loading,
        errorState,
        login,
        logout,
        apiError,setApiError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom Hook to use authentication
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
