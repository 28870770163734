import axios from "axios";
import { useState, useEffect } from "react";
import { setItem, getAuth, deleteItem, clear } from "../services/util";
import { Message } from "@mui/icons-material";
import Loader from '../components/loader/Loader';
import { useApi } from "../components/context/ApiContext";
import { useNavigate } from "react-router-dom";
// Axios instance (with default settings)
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "/api/",
  timeout: 80000, // 10 seconds timeout
});

const authAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "/api/",
  timeout: 8000, // 10 seconds timeout
});

// Public API Request Hook
export const usePublicRequest = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState({ code: null, message: "" });
  const { setApiError, setLoader } = useApi();

  const handleError = (code) => {
    switch (code) {
      case 400:
        setErrorState({
          code: 400,
          message: "Bad Request: The server could not understand the request.",
        });
        break;
      case 401:
        setErrorState({
          code: 401,
          message: "Unauthorized: Access is denied due to invalid credentials.",
        });
        break;
      case 403:
        setErrorState({
          code: 403,
          message:
            "Forbidden: You do not have permission to access this resource.",
        });
        break;
      case 404:
        setErrorState({
          code: 404,
          message: "Not Found: The requested resource could not be found.",
        });
        break;
      case 500:
        setErrorState({
          code: 500,
          message:
            "Internal Server Error: An unexpected error occurred on the server.",
        });
        break;
      default:
        setErrorState({
          code: "Unknown",
          message: "An unknown error occurred.",
        });
        break;
    }
  };
  const publicApiRequest = async ({
    url,
    method = "POST",
    data = {},
    spiner = false,
  }) => {
    setLoading(true);
    if (spiner) {
      setLoader(true);
    }
    setErrorState({ code: null, message: "" });

    try {
      const response = await axiosInstance({
        url,
        method,
        data: method === "POST" || method === "PUT" ? data : null,
      });

      const status = response?.status;
      if (status < 299) {
        setData(response.data); // Assume the data is in `response.data`
        return response;
      } else {
        setApiError(response?.data)
        handleError(status);
      
        return null;
      }
    } catch (err) {
      console.error("Public API Request Error: ", err.response);
      if(err.response?.status){
        handleError(err.response?.status);
        setApiError(err.response ? err.response?.data : "Request failed");
      }
     
      
    } finally {
      setLoading(false);
      setLoader(false);
    }
  };

  return {
    data,
    loading,
    errorState,
    publicApiRequest,
    loaderScreen: loading ? <Loader /> : null,
  };
};

// Private API Request Hook (with Authentication)
export const usePrivateRequest = () => {
  const navigate =useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState({ code: null, message: "" });
  const { setApiError, setLoader } = useApi();

  function convertS3ToCloudFront(s3Url) {
    const s3BaseUrl = "https://content.languagebook.s3.ap-south-1.amazonaws.com/";
    const cloudFrontBaseUrl = "https://d3i8yexdg2xdke.cloudfront.net/";

    if (s3Url.startsWith(s3BaseUrl)) {
        return s3Url.replace(s3BaseUrl, cloudFrontBaseUrl);
    } else {
         console.warn("Invalid S3 URL provided.",s3Url);
        return s3Url;
    }
}


  const handleError = (code) => {
    console.log("error",code)
    switch (code) {
      case 400:
        setErrorState({
          code: 400,
          message: "Bad Request: The server could not understand the request.",
        });
        break;
      case 401:
        setErrorState({
          code: 401,
          message: "Unauthorized: Access is denied due to invalid credentials.",
        });
        break;
      case 403:
        setErrorState({
          code: 403,
          message:
            "Forbidden: You do not have permission to access this resource.",
        });
        break;
      case 404:
        setErrorState({
          code: 404,
          message: "Not Found: The requested resource could not be found.",
        });
        break;
      case 500:
        setErrorState({
          code: 500,
          message:
            "Internal Server Error: An unexpected error occurred on the server.",
        });
        break;
      default:
        setErrorState({
          code: "Unknown",
          message: "An unknown error occurred.",
        });
        break;
    }
  };

  const privateApiRequest = async ({
    url,
    method = "POST",
    data = {},
    spiner = false,
    hasFile,
  }) => {
    setLoading(true);
    setErrorState({ code: null, message: "" });
    if (spiner) {
      setLoader(true);
    }
    const setHeaders = () => {
      if (hasFile) {
        return { "Content-Type": "multipart/form-data" };
      }
      return {}; // Default headers (will be empty if no file is present)
    };
    const token = getAuth("token"); // Assuming token is retrieved from localStorage

    if (!token) {
      setErrorState({ code: 403, message: "Authentication required" });
      setLoading(false);
      return;
    }

    try {
      const response = await authAxiosInstance({
        url,
        method,
        data: method === "POST" || method === "PUT" ? data : null, // POST/PUT send data, GET does not
        headers: {
          ...setHeaders(),
          Authorization: `Bearer ${token}`, // Set token in headers
        },
      });
      console.log("ppp",response)
      const status = response?.status;
      console.log(status,typeof(status),response)
      if (status < 299) {
        setData(response.data); // Assume the data is in `response.data`
        return response;
      } else {
        console.log("else",response)
        setApiError(response?.data)
        handleError(status);
        return null;
      }
    } catch (err) {
      console.log("else",err)
      setErrorState({ code: 500, message: "An unknown error occurred." });
      setApiError(err.response ? err.response?.data : "Request failed");

      if(err?.response?.status===401&&err?.response?.statusText==="Unauthorized")
      {
    localStorage.removeItem("token"); // Remove token from localStorage
    localStorage.removeItem("role"); // Remove role from localStorage
    localStorage.removeItem("isLoggedIn"); // Remove login status from localStorage
    navigate('/login')
      }
    } finally {
      setLoading(false);
      setLoader(false);
    }
  };

  return {
    data,
    loading,
    errorState,
    privateApiRequest,
    loaderScreen: loading ? <Loader /> : null,
    convertS3ToCloudFront
  };
};
