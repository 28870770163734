import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconCheck, IconClose, IconWarning } from '../../Icons';
import './Modal.css';

const defaultStyle = {
  position: 'absolute',
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-between",
  alignItems:"center",
  gap:".8rem",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "fit-content",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius:"7px",
  background: "linear-gradient(359.41deg, #EBFFA4 -15.46%, #FFFFFF 93.77%)",

  p: 4,
};

const closeButton = {
  position: 'absolute',
  top: '1rem',
  right: "1rem",
  backgroundColor:"transparent",
  border:"none",
  cursor:"pointer"
  

};
export default function ModalWrapper({
  close=false, 
  children, 
  modalStyle = defaultStyle, 
  description,
  openModal,
  closeModal,
  warning=false,
  image=false
}) {


 

  return (
    <>
     
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
       
        <Box sx={modalStyle} id="modalWrap">
        <button id={close?"":"closeBtn"} style={closeButton} onClick={()=>{closeModal(false)}}><IconClose/></button>
          {image?image:warning?<IconWarning/>:<IconCheck/>}
          {description && (
            <Typography id="modal-description" sx={{ mt: 2 ,color:"#435500",fontWeight:"600"}}>
              {description}
            </Typography>
          )}
<div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",gap:"1.2rem"}}>{children}</div>
          
          
        </Box>
      </Modal>
    </>
  );
}
