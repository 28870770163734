import React, { createContext, useMemo, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import{ blueLime,warmTheme, lightTheme, darkTheme,defaultTheme,greyGreen,browny,softPink } from '../../Themes/index'
import { alignPropType } from 'react-bootstrap/esm/types'





const Theme = ({ children }) => {

const currentTheme=lightTheme

const commonReset= {components: {
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          // General styles for the DatePicker root
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          fontSize: "14px",
        },
        paper: {
          // Styles for the dropdown calendar
          backgroundColor: "#ffffff",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            padding: "10px 12px",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0057b8", // Primary color
    },
    secondary: {
      main: "#ff7a00", // Secondary color
    },
  },   MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#121212',
          color: '#4caf50',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: `${currentTheme.palette.button.main} !important`,
          color: `${currentTheme.palette.primary.contrastText} !important`,
          boxShadow: `${currentTheme.palette.button.shadow} !important`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '.4rem',
          height: '33px !important',
          textOverflow: "nowrap",
          whiteSpace: "nowrap",
          borderRadius: 4,
          padding: '8px 16px',
          "&.Mui-disabled": {
            backgroundColor: "#2e3517b8 !important",
            color: "white !important",
            cursor: "not-allowed",
          },
          "&:hover": {
            backgroundColor: "#C3E159 !important", // Add !important here
          },
          '@media (max-width: 600px)': {
            height: '28px !important', // Mobile-specific styles
            fontSize: '0.875rem',
            minWidth: "fit-content !important",
          },
          '@media (min-width: 600px) and (max-width: 960px)': {
            height: '30px !important', // Tablet-specific styles
            fontSize: '0.9rem',
            minWidth: "fit-content !important",
          },
        },
      },
    },
    
    MuiPaper: {
      styleOverrides: {
        
        elevation2: {
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
          backgroundColor: `${currentTheme.palette.primary.dark} !important`,
        },
      },
    },
    MuiTooltip: {
        styleOverrides: {
          
            tooltip: {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
            backgroundColor: `${currentTheme.palette.primary.dark} !important`,
          },
        },
      },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: `${currentTheme.palette.primary.main} !important`,
          border: 'none',
          color:`${currentTheme.palette.primary.contrastText} !important`,
         fontSize: "16px",
         fontWeight: "600",
         textAlign:'left !important'


        },
        body:{
          
          fontSize: "14px",
          fontWeight: "500",
          color:"#474747",
          paddingInline:'2rem',
          textAlign:'left !important'
          
        }
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
         boxShadow:'none'

        }
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflow: `hidden`,
        },
      },
    
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
      paddingInline:'0px',
      paddingInline:'2rem'
      },
    },
  
},
MuiCheckbox: {
  styleOverrides: {
    root: {
      '&.Mui-checked': {
        color: '#202803 !important', // Color when checkbox is checked
      },
      '&.MuiCheckbox-indeterminate': {
        color: '#202803 !important', // Color for indeterminate state
      },
    },
  },
}
    ,
    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: `white !important`,
        },
      },
    
  }}
}





const theme = useMemo(() => createTheme({...currentTheme,...commonReset}),[])
    
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default Theme
