// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_main__SqrVH {
  width: 100%;
  height: 100%;
  padding-inline: 1.8rem;
}

[class^=Main_main]:has([class=loginWrap]) {
  padding: 0px;
}

[class*=calendarWrapper] {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #ebffa4 !important;
  outline: none !important;
}
[class*=calendarWrapper] input {
  padding: 0px !important;
  width: 100% !important;
  padding-block: 0.2rem !important;
  padding-left: 0.8rem !important;
  border: none;
  height: 28px;
  font-size: 12px;
}

[class^=Main_main] {
  padding: 1rem;
  padding-inline: 2rem;
}

input[type=password]::-ms-reveal {
  display: none;
}

@media (max-width: 900px) {
  .Main_main__SqrVH {
    padding-inline: 0.8rem;
  }
  .Main_MuiInputBase-input__xURDM {
    min-height: 0px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/Main.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACD,sBAAA;AACH;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,sBAAA;EACA,yBAAA;EACA,oCAAA;EACA,wBAAA;AAAJ;AACI;EACE,uBAAA;EACA,sBAAA;EACA,gCAAA;EACA,+BAAA;EACA,YAAA;EACJ,YAAA;EACI,eAAA;AACN;;AAGA;EACI,aAAA;EACA,oBAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAIA;EACI;IACI,sBAAA;EADN;EAGE;IACI,0BAAA;EADN;AACF","sourcesContent":[".main{\n    width: 100%;\n    height: calc(100%);\n   padding-inline: 1.8rem;\n   \n    // overflow: auto;\n}\n[class^=\"Main_main\"]:has([class=\"loginWrap\"]){\n    padding: 0px;\n}\n\n[class*=\"calendarWrapper\"] {\n    width: 100% !important;\n    background-color: #ffffff;\n    border: 1px solid #ebffa4 !important;\n    outline: none !important;\n    input {\n      padding: 0px !important;\n      width: 100% !important;\n      padding-block: 0.2rem !important;\n      padding-left: 0.8rem !important;\n      border: none;\n  height: 28px;\n      font-size: 12px;\n    }\n  }\n\n[class^=\"Main_main\"]{\n    padding: 1rem;\n    padding-inline: 2rem;\n}\n\ninput[type=\"password\"]::-ms-reveal {\n    display: none;\n  }\n\n\n@media (max-width:900px) {\n    .main{\n        padding-inline: .8rem;\n    }\n    .MuiInputBase-input{\n        min-height: 0px  !important;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Main_main__SqrVH`,
	"MuiInputBase-input": `Main_MuiInputBase-input__xURDM`
};
export default ___CSS_LOADER_EXPORT___;
