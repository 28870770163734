import React from 'react'
import style from './ListCard.module.scss'
import { IconCalendar, IconEdit, IconWhatsapp, IconIdea, IconSave,IconEdit1, IconDownArrow ,IconDelete1, IconGotos} from '../../Icons'
import { useNavigate} from 'react-router-dom'
import { Button, Tooltip } from 'react-bootstrap'
import {usePrivateRequest} from "../../hooks/useRequests"; 
import ModalWrapper from '../../components/Modal/ModalWrapper'
import { useState,useEffect } from 'react'
import { WhatsApp } from '@mui/icons-material'
import useMediaQuery from "@mui/material/useMediaQuery";
import { getItem } from '../../services/util'
import ShowMoreText from "react-show-more-text";
import { useUser } from '../../components/context/Userprovider'

function ListCard({ loading=false,data, examTrainee, resultTrainee, onEdit ,saved=false,admin=false,whatsApp=true,modal=false,savedReload=()=>{console.log("")},reload=()=>{console.log("")}}, onNotify) {
  const [modalOpen,setModalOpen]=useState(false)
  const [error,setError]=useState(false)
  const [msg,setMsg]=useState("")
  const navigate = useNavigate()
  const { privateApiRequest ,errorState} = usePrivateRequest();
  const isMobile = useMediaQuery("(max-width:900px)");
const handleSave=async()=>{
if(data?.id&& resultTrainee){
  try{
    const arg={...data,result_id:data?.id}
    const response = await privateApiRequest({
      url: "studentsapp/saved-results/create/",
      method: "POST",
      data: arg,
    })
  if(response){
    console.log(response)
    setError(false)
    setMsg("Item saved!")
    setModalOpen(true)
  }
  else{
    setError(true)
    setMsg("Error occured!")
    setModalOpen(true)
  }
  }catch(e){
    setMsg("Error occured!")
    console.log(e)
    setError(true)
    setModalOpen(true)
   
  }
}
}
const {userData}=useUser()
const handleDelete=async()=>{

  let url;
  const userId=data?.id;
if (admin === "result") {
  url = `adminapp/result/delete/${userId}`;
} else if (admin === "exam") {
  url = `adminapp/exams/delete/${userId}`;
} else if(saved) {
  url = `/studentsapp/saved-results/delete/${userId}`
}
  try{
  
    const arg={}
    const response = await privateApiRequest({
      url:url,
      method: "DELETE",
      data: arg,
    })
    
    console.log("response eeror",errorState)
    if(response?.status<299){
      setError(false)
      setMsg("Item deleted successfully")
      setModalOpen(true)
      
    }else{
      setMsg("Unable to delete the item")
      setModalOpen(true)
      setError(true)
    }
    

    
   
  }catch(e){
    setMsg("Error occured!")
    console.log(e)
    setError(true)
    setModalOpen(true)
   
  }
 }

  return (
    
    <div className={style.listWrapper}>
      <div className={style.header}><div>{data?.exam_topic}</div>  <div className={style.calendar}><IconCalendar /> {data?.exam_date} </div></div>
   
      <ShowMoreText
                /* Default options */
                lines={3}
                more="Show more"
                less="Show less"
                anchorClass={style.showMoreClickable}
                expanded={false}
                width={0}
                truncatedEndingComponent={"... "}
                className={style.showMore}
            >
        {data?.exam_description?data?.exam_description:data?.result_description	}
        </ShowMoreText>
      

      <div className={style.footer}>
        <div className={style.leftSide}>
          {examTrainee || resultTrainee ? (
            <div className={style.iconSquare}>{data?.exam_center}</div>
          ) : null}
          {resultTrainee && !saved &&getItem("isLoggedIn")&&!data?.is_saved ? (
            <div>
              <Button style={{ border: "1px solid rgba(161, 200, 21, 1)",
                 color: "black",
                 background: 'var(--Button-Style, linear-gradient(89deg, #A1C815 -79.54%, #A8CD22 -57.77%, #B1D434 -33.77%, #A3CA19 -32.71%, #ACD02A -32.38%, #B2D436 -23.22%, #EBFFA6 138.22%))' }} onClick={()=>{handleSave()}}><IconSave />{isMobile?"Save":"Save Result"}</Button>
            </div>
          ) : null}
        </div>

        <div className={style.rightSide}>
          {examTrainee && (
            <div id={style.idea} onClick={()=>{
              !modal&&navigate("/registration_student", {
                state: { name: data?.exam_topic,student:true }
              });
              
            }}>
              <IconIdea />
            </div>
          )}
          {!examTrainee && resultTrainee && (
            <div className={style.newlyPublihed}>{isMobile?"New":"Newly Published"}</div>
          )}
          {!examTrainee && !resultTrainee && (
             <Tooltip title="Edit">
            <button onClick={() => { onEdit && navigate(onEdit, {
              state: { id: data?.id },
            }); }}>
              <IconEdit1 /> Edit
            </button>
            </Tooltip>
          )}
          { (!resultTrainee  && whatsApp&&!getItem("adminId"))&&!userData?.notification_status&& (
            <button onClick={() => { onNotify && navigate("/profile"); }}>
             <span style={{padding: "5px"}}> <IconWhatsapp /> </span>  Notify me <span style={{padding: "5px"}}></span>
            </button>
          )}
          { ((!resultTrainee  && !examTrainee)||(resultTrainee&&saved)) &&(
            <Tooltip label="Delete">
            <button onClick={() => {handleDelete() }}>
            <IconDelete1/>
            </button>
            </Tooltip>
          )}
  <Tooltip title="Link" placement="top">
<button id={style.goto} onClick={()=>{
  window.open(data?.website_url||data?.result_url||"", "_blank"); // Ensure it's an absolute URL
}} >
          <IconGotos/>
            </button>
            </Tooltip>
            </div>
      </div>
      <ModalWrapper
        openModal={modalOpen}
        closeModal={setModalOpen}
        description={msg}
        warning={error}
       
      >
        <Button
          onClick={() => {
            console.log("hggjh");
            setModalOpen(false);
            reload()
            saved&&savedReload()
          }}
        >
          Done
        </Button>
      </ModalWrapper>
    </div>
  )
}

export default ListCard